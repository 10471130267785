import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRequest, getRequest } from "utils/requests";

import { switchLookingFor, getUrlParameter, actionCookies } from "utils/common";

import { initAuthorized } from "utils/apiClient";

import * as FILTER from "data/filter";

const setAuthHeader = (token, fingerprint) => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("fingerprinthash", fingerprint);
    initAuthorized();
  }
};

export const register = createAsyncThunk(
  "auth/register",
  async (valueRegister, thunkAPI) => {
    try {
      let click_id = "";
      const utm_netw = getUrlParameter("utm_netw");
      const clickid = getUrlParameter("clickid");
      const deviceMemory = navigator?.deviceMemory ?? 0;
      const deviceData = {
        memory: deviceMemory,
        height: window.screen.height,
        width: window.screen.width,
      };

      if (utm_netw === "MGID") click_id = getUrlParameter("tid");
      else click_id = getUrlParameter("msclkid");

      const REQ_BODY = {
        user_real_name: valueRegister?.name,
        email: valueRegister?.email,
        password: valueRegister?.password,
        day: valueRegister?.bDay?.value,
        month: 1,
        year: valueRegister?.bYear?.value,
        gender: switchLookingFor(valueRegister?.looking?.value),
        click_id: click_id,
        clickid: clickid,
        tail: window?.location?.search ?? "",
        deviceData: deviceData,
      };
      const res = await postRequest("/api/v3/auth/register", REQ_BODY);
      const token = res?.token;
      if (!token) {
        return thunkAPI.rejectWithValue(res);
      }
      if (res?.fingerprint) {
        localStorage.setItem("fingerprinthash", res?.fingerprint);
      }
      setAuthHeader(token, res?.fingerprint);
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logIn = createAsyncThunk(
  "auth/login",
  async (valueLogin, thunkAPI) => {
    try {
      const res = await postRequest("/api/v3/auth/login", {
        email: valueLogin.email,
        password: valueLogin.password,
      });
      if (res?.fingerprint) {
        localStorage.setItem("fingerprinthash", res?.fingerprint);
      }
      const token = res?.token;
      if (token) {
        setAuthHeader(token, res?.fingerprint);
        return res;
      }
      return thunkAPI.rejectWithValue(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const logInGoogle = createAsyncThunk(
  "auth/loginGoogle",
  async (valueLogin, thunkAPI) => {
    try {
      const res = await postRequest("/api/v3/auth/google-auth", valueLogin);
      if (res?.fingerprint) {
        localStorage.setItem("fingerprinthash", res?.fingerprint);
      }
      const token = res?.token;
      setAuthHeader(token, res?.fingerprint);
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logOut = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const res = await postRequest("/api/v3/auth/logout");
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const refreshUser = createAsyncThunk("auth/refresh", (_, thunkAPI) => {
  // Reading the token from the state via getState()
  const persistedToken =
    actionCookies("token") || localStorage.getItem("token") || null;
  const fingerprint =
    actionCookies("fingerprinthash") ||
    localStorage.getItem("fingerprinthash") ||
    null;
  if (persistedToken === null) {
    // If there is no token, exit without performing any request
    return thunkAPI.rejectWithValue("Unable to fetch user");
  }
  setAuthHeader(persistedToken, fingerprint);
  return persistedToken;
});

export const getUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async (_, thunkAPI) => {
    try {
      const res = await getRequest("/api/user/info");
      if (res?.fingerprint) {
        localStorage.setItem("fingerprinthash", res?.fingerprint);
      }
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getMyProfile = createAsyncThunk(
  "auth/getmyprofile",
  async (_, thunkAPI) => {
    try {
      const res = await getRequest("/api/user/myProfile");
      if (window?.posthog && !window.posthog?.__loaded) {
        window.posthog.init("phc_m5TIYPDyVFWQMkrOP527y2C8JjQw7gglkc5AGxS7FTZ", {
          api_host: "https://posthog.cdndate.net",
          session_recording: {
            recordCrossOriginIframes: true,
          },
        });
        window.posthog.identify(res?.user_info?.user_detail?.external_id, {
          name: res?.user_info?.user_detail?.name,
        });
      }
      return res.user_info;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchFaqAndTutorial = createAsyncThunk(
  "auth/fetchfaqandtutorial",
  async (_, thunkAPI) => {
    try {
      const resFaq = await getRequest("/api/v3/faq");
      const resTutorial = await getRequest("/api/v3/tutorial");
      return { faq: resFaq.result, tutorial: resTutorial.result };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchIsFBConversion = createAsyncThunk(
  "auth/fetchisfbconversion",
  async (_, thunkAPI) => {
    try {
      const res = await getRequest("/api/marketing/checkCapi");
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchRoles = createAsyncThunk("auth/fetchroles", async () => {
  try {
    const resRole = await getRequest("/api/v3/user-profile/role");
    return resRole?.result;
  } catch (error) {
    return { status: false };
  }
});

export const fetchTail = createAsyncThunk("auth/fetchtail", async () => {
  try {
    const resTail = await postRequest("/api/tail/getUTMTail");
    return resTail;
  } catch (error) {
    return { status: false };
  }
});

export const getProfileInfo = (token) => {
  return getRequest(`user/myProfile`, token ? { cancelToken: token } : {}).then(
    (res) => {
      if (!res || !res?.data) {
        return {};
      }
      if (window?.posthog && !window.posthog?.__loaded) {
        window.posthog.init("phc_m5TIYPDyVFWQMkrOP527y2C8JjQw7gglkc5AGxS7FTZ", {
          api_host: "https://posthog.cdndate.net",
          session_recording: {
            recordCrossOriginIframes: true,
          },
        });
        window.posthog.identify(
          res?.data?.user_info?.user_detail?.external_id,
          {
            name: res?.data?.user_info?.user_detail?.name,
          }
        );
      }

      if (!FILTER.FILTER_TYPE || FILTER.FILTER_TYPE.length === 0) {
        return res?.data?.user_info;
      }

      let resArrayUserInfo = [];
      let resArrayRefedencies = [];

      for (let key in res?.data?.user_info) {
        if (key !== FILTER.USER_INFO_FILTER_TYPE) {
          resArrayUserInfo[key] = res?.data?.user_info[key];
        }
      }

      for (let key in res?.data?.user_info?.referencies_list) {
        if (key !== FILTER.FILTER_TYPE) {
          resArrayRefedencies[key] =
            res?.data?.user_info?.referencies_list[key];
        }
      }

      const filterArray = res?.data?.user_info?.referencies_list[
        FILTER.FILTER_TYPE
      ].filter(function (e) {
        return this.indexOf(e[FILTER.FILTER_NAME_FIELDS]) < 0;
      }, FILTER.ARR_FILTER_FIELDS);

      return {
        ...resArrayUserInfo,
        ...{
          referencies_list: {
            ...resArrayRefedencies,
            ...{ [FILTER.FILTER_TYPE]: filterArray },
          },
        },
      };
    }
  );
};
