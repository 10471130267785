import {
  Box,
  Icon,
  CheckV,
  Button,
  ModalWindowModern,
  AudioPlayer,
  Loader,
} from "components/atomsKit/Elements";
import { Timeonchat, Textonchat, Image } from "components/designAtom/Elements";

import cn from "classnames";

import { cutTextforToltip } from "utils/common";
// @ts-ignore
import classes from "./AddFileModal.module.scss";

const AddFileModal = ({
  modalIsOpen,
  closeModal,
  uploadFileNavConfig,
  handleUploadNavBtn,
  handleUploadFile,
  uploadFiles,
  handleItemMedia,
  selectedMedia,
  handleSendMedias,
  handleDelMedia,
  handleContent,
  price = {},
  uploadLoader = {},
}) => {
  const selectNav = uploadFileNavConfig.find((item) => item.selected);
  const files = uploadFiles[selectNav.nameCredit];
  const countLoader = uploadLoader[selectNav.nameCredit].count;

  const checkIfItemExists = (id) => {
    return selectedMedia.some((item) => item.id === id);
  };

  return (
    <ModalWindowModern
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      hideCloseButton
    >
      <Box
        flex
        width100
        position="relative"
        padding="0 14px 0 14px"
        className={classes.wrap}
      >
        <button className={classes.btn} onClick={closeModal}>
          <Icon icon={"ok"} className={classes.btnClose} />
        </button>
        <Box className={classes.nav}>
          {uploadFileNavConfig.map((item) => (
            <button
              key={item.nameCredit}
              onClick={() => {
                handleUploadNavBtn(item.id);
              }}
              className={cn(
                classes.btnNav,
                item.selected && classes.btnNav_selected
              )}
            >
              <Image
                name={item.selected ? item.imgSelected : item.img}
                bgImg
                classNameIcon="btnIco"
              />
            </button>
          ))}
        </Box>
        <Box className={classes.body} padding="10px">
          <Timeonchat
            text={`The cost of sending one ${selectNav.nameCredit} is ${
              price[selectNav.creditInfo]
            } credits`}
            className={classes.textCredits}
          />
          <Box
            flex
            directionColumn
            justify={"between"}
            className={classes.wrapListAndBtn}
          >
            {files.length > 0 || countLoader > 0 ? (
              <Box
                className={cn(
                  files[0]?.content_type === "audio"
                    ? classes.listWrapScrollAudio
                    : classes.listWrapScroll
                )}
              >
                {files.map((item, index) =>
                  item.content_type === "audio" ? (
                    <Box className={classes.itemListAudioFile} key={index}>
                      <button
                        className={classes.audioItem}
                        onClick={() => handleItemMedia(item)}
                      >
                        <CheckV
                          className={classes.chekBoxAudio}
                          value={checkIfItemExists(item.id)}
                        />
                        <AudioPlayer src={item.link} />
                      </button>
                      <Box flex width100 justify={"between"} align={"center"}>
                        <Textonchat
                          text={cutTextforToltip(item.filename, 10)}
                        />
                        <button
                          className={classes.actionBtnItem}
                          onClick={() => {
                            handleDelMedia(item);
                          }}
                        >
                          <Image
                            name="Trash-red"
                            className={classes.trachIcon}
                          />
                        </button>
                      </Box>
                    </Box>
                  ) : (
                    <Box className={classes.itemListFile} key={index}>
                      <button
                        className={classes.imageItem}
                        onClick={() => handleItemMedia(item)}
                      >
                        <CheckV
                          className={classes.chekBox}
                          value={checkIfItemExists(item.id)}
                        />
                        <img
                          src={item[selectNav.labelImage]}
                          alt=""
                          className={classes.img}
                          loading="lazy"
                        />
                      </button>
                      <Box flex width100 justify={"around"} align={"center"}>
                        <button
                          className={classes.actionBtnItem}
                          onClick={() => {
                            handleContent({
                              original: item[selectNav.original],
                              thumbnail: item[selectNav.labelImage],
                              type: selectNav.type,
                              payed: true,
                            });
                          }}
                        >
                          <Image name="photo-full" />
                        </button>
                        <button
                          className={classes.actionBtnItem}
                          onClick={() => {
                            handleDelMedia(item);
                          }}
                        >
                          <Image
                            name="Trash-red"
                            className={classes.trachIcon}
                          />
                        </button>
                      </Box>
                    </Box>
                  )
                )}
                {countLoader > 0 && (
                  <Box className={classes.itemListFile}>
                    <Loader isLoading={true} forDiv />
                  </Box>
                )}
              </Box>
            ) : (
              <Box className={classes.empty} flex directionColumn>
                <Image name={"not_found_results_2"} />
                <p className={classes.textEmpty}>No files yet</p>
                <p className={classes.textWrap}>
                  Use
                  <Image
                    name={"attach-upload-ico-grey"}
                    className={classes.icoMinDownload}
                  />
                  “Upload” button to add files
                </p>
              </Box>
            )}

            <Box
              flex
              align="center"
              justify={"between"}
              marginTop="10px"
              className={classes.wrapSendBtn}
            >
              <label className={classes.uploadBtn}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="popup_inp_file1"
                  accept={selectNav.accept}
                  onChange={(e) => {
                    // @ts-ignore
                    handleUploadFile(e.target.files, selectNav.AWS);
                  }}
                />
                <Image name={"attach-upload-ico"} />
              </label>
              <Button
                onClick={handleSendMedias}
                padding="0 20px 0 20px"
                text="Send"
                iconSvg={"attach-send-ico"}
                fitContent
                className={classes.sendBtn}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalWindowModern>
  );
};

export default AddFileModal;
