import Resizer from "react-image-file-resizer";

import moment from "moment";

import { postRequest } from "utils/requests";

import * as AWS from "data/aws";

const AwsApi = (
  data,
  file,
  mode,
  bucketName,
  onChangeSendMessage,
  resetLoader
) => {
  if (mode === "add") {
    switch (bucketName) {
      case AWS.IMG_BUCKET:
        postRequest("/api/v3/click-history/aws/generate-link", data)
          .then((res) => {
            postRequest("/api/files/image", res?.data).then(() => {
              onChangeSendMessage({
                filename: res?.data?.filename,
                message_content: res?.data?.link,
                message_type: "SENT_IMAGE",
              });
              resetLoader();
            });
          })
          .finally(() => {
            resetLoader();
          });
        break;
      case AWS.VIDEO_BUCKET:
        postRequest("/api/v3/video_converter/new-user-video-convert", data)
          .then((res) => {
            postRequest("/api/files/video", res?.data).then(() => {
              if (res?.status) {
                postRequest("/api/v3/video_converter", {
                  type: "link",
                  data: res?.data?.link,
                }).then(() => {
                  if (file?.type !== "video/mp4") {
                    postRequest(`v3/video_converter/video-convert`, {
                      videos: res?.data?.link,
                    }).then((res) => {
                      onChangeSendMessage({
                        filename: res?.data?.filename,
                        message_content: res?.data?.link,
                        message_type: "SENT_VIDEO",
                      });
                    });
                  } else {
                    onChangeSendMessage({
                      filename: res?.data?.filename,
                      message_content: res?.data?.link,
                      message_type: "SENT_VIDEO",
                    });
                  }
                });
              }
            });
          })
          .finally(() => {
            resetLoader();
          });
        break;
      case AWS.AUDIO_BUCKET:
        postRequest("/api/v3/video_converter/user-audio-convert", data)
          .then((res) => {
            postRequest("/api/files/audio", res?.data).then(() => {
              onChangeSendMessage({
                filename: res?.data?.filename,
                message_content: res?.data?.link,
                message_type: "SENT_AUDIO",
              });
            });
          })
          .finally(() => {
            resetLoader();
          });
        break;
      default:
        break;
    }
  } else if (mode === "avatar") {
    postRequest("/api/v3/click-history/aws/generate-link", data).then((res) => {
      postRequest("/api/user/updateUserAvatar", {
        photo_link: res?.data?.link,
      }).then(() => {
        onChangeSendMessage();
      });
    });
  } else if (mode === "addPhotoProfile") {
    postRequest("/api/v3/click-history/aws/generate-link", data).then((res) => {
      onChangeSendMessage(res?.data);
    });
  }
};

const uploadFile = async (
  file,
  fileName,
  dir,
  bucketName,
  index,
  onChangeSendMessage,
  mode,
  resetLoader
) => {
  const newFileName = moment().unix() + fileName;
  const fileParts = file?.name?.split(".");
  const fileType = fileParts[fileParts?.length - 1];

  if (bucketName === AWS.IMG_BUCKET || bucketName === AWS.MEN_IMAGES) {
    AWS.AWS_THUMBS.forEach((size) => {
      Resizer.imageFileResizer(
        file,
        size.width,
        size.height,
        fileType,
        90,
        0,
        async (uri) => {
          const formData2 = new FormData();
          formData2.append("file", uri);
          formData2.append(
            "newFileName",
            `w-${size.width}-h-${size.height}-${newFileName}.${fileType}`
          );
          formData2.append("fileName", fileName);
          formData2.append("dir", dir);
          formData2.append("bucketName", bucketName);
          formData2.append("index", index);

          postRequest("/api/v3/click-history/aws/generate-link", formData2);
        },
        "blob"
      );
    });
  }

  const formData = new FormData();

  formData.append("file", file);
  formData.append("newFileName", `${newFileName}.${fileType}`);
  formData.append("fileName", fileName);
  formData.append("dir", dir);
  formData.append("bucketName", bucketName);
  formData.append("index", index);

  AwsApi(formData, file, mode, bucketName, onChangeSendMessage, resetLoader);
};

export default uploadFile;
